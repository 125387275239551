import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import TopBunerProduct from '../../components/top-buner/top-baner-product';
import OfficeAtMap from '../../components/office-on-map/office-on-map';
import ImagesView from '../../components/images-view/images-view';
import FormQuickOrder from '../../components/form-quick-oreder/form-quick-order';
import ZaanenGoatfeature from '../../images/goats/zaanen_breed_goats/feature-zaaanenka.jpg'
import ZaanenGoatGallery1 from '../../images/goats/zaanen_breed_goats/zaanenskaia-koza-i-kozel.jpg'
import ZaanenGoatGallery2 from '../../images/goats/zaanen_breed_goats/zaanenskaia-kozy-na-ferme.jpg'
import ZaanenGoatGallery3 from '../../images/goats/zaanen_breed_goats/zaanenskii-kozel.jpg'
import ZaanenGoatGallery4 from '../../images/goats/zaanen_breed_goats/plemennie-zaanenskie-kozy-na-ferme.jpg'
import ZaanenGoatGallery5 from '../../images/goats/zaanen_breed_goats/zaanenskaia-koza.jpg'


const ZaanenPoroda = (props) => {
  const breadCramb = [{name:'Главная', url:'/', status: false}, 
    {name:'Описание Зааненской породы коз', url:'#', status:true}]

    const indicators = [
      {name: 'Надой за 305 дней(л)', progress: 1121, benchmark:1200},
      {name: 'Надой в день в среднем(л)', progress: 3.67, benchmark:4},
      {name: 'Жирность молока(%)', progress: 3.3, benchmark:6},
      {name: 'Белки(%)', progress: 2.9, benchmark:5},
      {name: 'Жир + белки(%)', progress: 6.2, benchmark:10},
      {name: 'Лактация дней', progress: 330, benchmark: 365},
      {name: 'Вес коз', progress: 70, benchmark:100},
      {name: 'Приплод', progress: 225, benchmark:300},
  ]

    const galleryImage = [
      {
        src: ZaanenGoatGallery1,
        width: 3,
        height: 2,
        alt: 'Заанеская попрода коз',
        title: 'Заанеская попрода коз'
      },
      {
        src: ZaanenGoatGallery2,
        width: 3,
        height: 2,
        alt: 'Заанеская попрода коз',
        title: 'Заанеская попрода коз'
      },
      {
        src: ZaanenGoatGallery3,
        width: 3,
        height: 2,
        alt: 'Заанеская попрода коз',
        title: 'Заанеская попрода коз'
      },
      {
        src: ZaanenGoatGallery4,
        width: 3,
        height: 2,
        alt: 'Заанеская попрода коз',
        title: 'Заанеская попрода коз'
      },
      {
        src: ZaanenGoatGallery5,
        width: 3,
        height: 2,
        alt: 'Заанеская попрода коз',
        title: 'Заанеская попрода коз'
      }
    ];
  return (
    <div>
        <SEO title="Зааненская порода коз: описание, фото, характеристики"
            description="Зааненская порода коз востребована, как в домашних хозяйствах, так и хозяйствах коммерческой направленности, в силу высоких показателей удойности.">                          
      </SEO>
      <Layout>
      <TopBunerProduct TopImage={''}  
                      titileH1={'Описание Зааненской породы коз'}
                      comment={""}
                      FormView={true}
                      LogoView={false}
                      typeofBunner={'descrition-page'}
                      breadCrumbs={breadCramb}
                      indicators={indicators}>
            </TopBunerProduct>
        <Container fluid>
         <Row className="mt-5">
              <Col md="6" xs='12' className="text-justify ">
                <div className="pl-md-5">
                    <h1 className="text-center text-md-left ">Зааненская порода коз</h1>
                    <p className="text-justify">
                      Зааненская порода коз востребована, как в домашних хозяйствах, так и хозяйствах коммерческой направленности, 
                      в силу высоких показателей удойности. Здоровый образ жизни и качественное питание набирают все большую популярность 
                      в наше время и все больше людей организовывают небольшие фермы для обеспечения своих семей натуральными продуктами. 
                      Содержание зааненских коз в таких хозяйствах будет лучшим решением! Неприхотливость и молочное направление этой 
                      породы обеспечит семью молоком и молочными продуктами в излишке.
                    </p>
                <Row>
                  <Col md="12">
                    <h4>Оглавление:</h4>
                  </Col>
                  <ul className="pl-5">
                    <li><a href="#origin">Происхождение породы</a></li>
                    <li><a href="#description">Описание породы</a></li>
                    <li><a href="#productivity">Продуктивность Альпийских коз</a></li>
                    <li><a href="#care">Уход и содержание</a></li>
                    <li><a href="#diet">Рацион коз</a></li>
                    <li><a href="#dignity">Достоинства  породы</a></li>
                  </ul>
                </Row>
                </div>
              </Col>
              <Col md="6" xs='12' className="text-center">
                <img src={ZaanenGoatfeature} className="w-100" style={{maxWidth:"500px"}}  
                                                            alt="Фото козы Зааненской породы" 
                                                            title="Фото козы Зааненской породы" />
              </Col>
            </Row>
        </Container>
        
         <Container fluid className="mt-3">
           <Row>
             <Col md="12" className="pl-md-5">
               <h2 id="origin">Происхождение породы</h2>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
              <p className="text-justify">
                Свое имя порода получила от названия Заанентальской долины  в которой она была выведена путем народной селекции. 
                Благоприятные природные условия и географическое расположение долины (Заанентальская долина расположена в районе 
                Бернских Альп в Швейцарии), безусловно, внесли свой вклад в формирование молочных свойств породы.  
                Но не только горный воздух, зеленые луга и талая вода оказали чудотворное воздействие на породу. 
                В течении многих столетий фермеры скрещивали наиболее продуктивных особей, с целью наследования их качеств. 
              </p>
            </Col>
            <Col md="6" sm="12" className="pl-md-5">
              <p className="text-justify" className="text-justify">
                Широкую популярность Зааненская порода приобрела после участия на сельскохозяйственных выставках Европы в 1893. 
                После 1905 года чистокровные особи ввозились в Россию. Но после революции поставки прекратились, 
                а ввезенные козы распространились по многим регионам страны, где приспосабливаясь к местным климатическим 
                условиям, зааненские козы менялись и в результате образовались локальные типы заанеской породы. 
                Одной из наиболее распространенных на территории России пород, в селекции которой участвовала Зааненская порода, 
                является «Русская белая коза». В других странах также есть ответвления данной породы: в Германии это Немецкая 
                Белая Благородная коза, в Румынии зааненку зовут Банат Уайт.
              </p>
              <p className="text-justify" className="text-justify">
                В наше время приобрести чистопородистых коз можно в специализированных фермерских хозяйствах, которые регистрируют 
                родословные своих животных. Достоверность которых, можно проверить в племенных центрах.
              </p>
            </Col>
           </Row>
         </Container>
        <Container fluid className="mt-3">
          <h2 className="text-center">Фото коз Зааненской породы</h2>
          <ImagesView galleryImage={galleryImage} />
        </Container>
         <Container fluid className="mt-5">
           <Row>
            <Col md="12" className="pl-md-5">
               <h2 id="description">Описание породы</h2>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
              <p className="text-justify">
                Животные достаточно крупные. Козлы в холке могут достигать 1 м., вес 120 – 140 кг; козы 70 см в холке и вес 60 – 80 кг. 
                Окрас преимущественно белый, может быть кремовый. Допускаются пятна на коже и небольшие вкрапления цветной шерсти. 
                Бывают рогатыми и комолыми. Конституция ярко выраженного молочного типа, шарообразное вымя, голова легкая, сухая, 
                уши прямые, направлены вперед и вверх. На шее бывают отростки кожи называемые «сережки».
              </p>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
             <p className="text-justify">
                Зааненские козлы плодовиты и быстро развиваются до спелого возраста. .  Козел имеет более развитую мышечную массу. 
                Потомство одного козла на десять маток  от 17 до 25 козлят в год.
              </p>
              <p className="text-justify">Козлята зааненской породы быстро набирают массу при рождении они весят от 3 до 4.5 килограмм, 
                в 2 месяца весят уже от 9 до 12 килограмм. К 12 месяцам набирают от 30 до 45 килограмм.
              </p>
             </Col>
           </Row>
         </Container>
         <Container fluid className="mt-5">
           <Row>
            <Col md="12" className="pl-md-5">
               <h2 id="productivity">Продуктивность коз альпийской породы</h2>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
             <h3>Удойность</h3>
              <p className="text-justify">
                Основное направление для селекционеров, при выводе этой породы,  было высокая молочная продуктивность. 
                После первого окота коза приносит около 700 литров молока в год, с периодом лактации 11 месяцев .  
                Хорошие показатели удойности проявляются не сразу (примерно на четвертый год жизни), козу нужно постепенно раздаивать. 
                Также на молочность козы могут повлиять условия ее содержания.
              </p>
              </Col>
              <Col md="6" sm="12" className="pl-md-5">
              <p className="text-justify">
                Считается что зааненская порода имеет самый низкий процент жира и белка из всех пород. Но с тех пор как молзаводы стали 
                платить за жир и белок, а не за килограмм молока, ситуация поменялась. Фермеры стали целенаправленно проводить селекцию на жир и белок. 
                В результате сейчас при среднем суточном удое за лактацию 4,5 кг молока в день, 
                содержание жира и белка в молоке составляет 3,6% и 3,2% соответственно.
              </p>
             </Col>
             <Col md="12" className="pl-md-5">
               <h2 id="care">Уход и содержание</h2>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
              <p className="text-justify">
                Для того, чтобы поддерживать высокий уровень удойности у коз зааненской породы следует с должным вниманием отнестись к 
                условиям их содержания.
              </p>
              <ul>
                <li>- Козлятник должен быть сухой и теплый и чистый. </li>
                <li>- Темпиратура от 6 до 18 градусов. </li>
                <li>- В помещении должен попадать дневной свет. </li>
                <li>- Помещение должно проветриваться, но без сквозняков.</li>
                <li>- Пол может быть деревянный или бетонный. Со стоками для фекалий.</li>
                <li>- Для одной особи необходимо не менее двух метров пространства.</li>
                <li>- В доль стен оборудуются лежанки. Они должны быть не наполу.</li>
                <li>- У коз должны быть чистые и сухие подстилки.</li>
                <li>- Козлы должны содержатся отдельно.</li>
              </ul>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
             <h3>Рацион Зааненских коз</h3>
              <ul type="circle">
                <li>- Не следует кормить коз человеческой пищей.</li>
                <li>- Питьевая вода должна быть теплой.</li>
                <li>- В рационе должна присутсвовать поваренная соль.</li>
                <li>- Следует давать подкормки: кормовую свеклу, капусту, яблоки, картофель, морковь.</li>
                <li>- В зимнее время года следует отдавать предпочтение бобово-злаковому силосу, а также периодически давать веники из березовых, липовых, дубовых прутьев.  </li>
              </ul>
              <p className="text-justify">
                Зааненская порода очень хорошо подходит для крупных промышленных ферм. Эти козы спокойные и послушные, 
                достаточно стрессоустойчивые. На белой шерсти хорошо видны метки маркером, что позволяет легко находить 
                нужное животное в стаде. Из-за нежной белой кожи зааненские козы не очень подходят для выпасного содержания 
                в жарком климате. Эти козы предпочитают находиться внутри, под крышей, где сухо и тепло.
              </p>
             </Col>
           </Row>
         </Container>
         <FormQuickOrder className="mt-3" />
         <Container fluid className="mt-5">
           <Row>
             <Col md="6" sm="12" className="pl-md-5">
               <h2 id="diet">Рацион коз</h2>
              <p className="text-justify">Альпийские козы не очень привередливы к рациону, даже говорят что они всеядны. Поэтому каких-то особых требований кормления этих коз нет. В летнее время года они вполне полноценно питаются с пастбища ,  но в некоторых случаях для увеличения удойности рекомендуется давать козам овощи минеральные добавки и витамины. </p>
              <p className="text-justify">Из особенностей кормления можно выделить отношение альпийских коз к питьевой воде. Вода должна быть всегда чистой как и поилка. Бывали случай когда козы этой породы отказывались пить не свежую воду и страдали от обезвоживания. Питьевой воды должно быть в достатке и за этим следует следить, так как от этого фактора зависит молочная продуктивность животного.</p>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
             <h2 id="dignity">Достоинства и недостатаки породы</h2>
             <h3>Качества:</h3>
              <ul>
                <li>Высокая удойность</li>
                <li>Качество молока</li>
                <li>Длинный период лактации</li>
                <li>Быстрое половое созревание</li>
                <li>Высокая плодовитость</li>
                <li>Адаптивность</li>
                <li>Передача качеств породы потомству</li>
              </ul>

           <h3> Недостатки:</h3>
              <li>Вислозадость</li>
              <li>Слабо развитая мышечная масса</li>
              <li>Вислозадость</li>

             </Col>
           </Row>
         </Container>
        <OfficeAtMap />
      </Layout>
      
    </div>
  );
};

export default ZaanenPoroda;